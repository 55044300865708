import React, { useState, useCallback, useEffect } from "react";
import styles from "./index.module.scss";

const Page4 = ({ scrollToAnchor }) => {

	return (
		<div className={styles.page}>
			<div className={styles.text}>獨創階梯式教學法</div>
			<div className={styles.text2}>
				<div className={styles.message}>分段學習，確認孩子完整理解</div>
				<div className={styles.message}>輔導老師伴學+授課老師指導</div>
			</div>
			<div className={styles.bg2}>
				<div className={styles.imgContent}>
					<img src={"./assets/img/階梯教學/階梯教學1.jpg"} alt="bg" />
				</div>
				<div className={styles.imgContent}>
					<img src={"./assets/img/階梯教學/階梯教學2.jpg"} alt="bg" />
				</div>
				<div className={styles.imgContent}>
					<img src={"./assets/img/階梯教學/階梯教學3.jpg"} alt="bg" />
				</div>
			</div>
		</div>
	);
};
export default Page4;
