import React, { useEffect, useRef } from "react";
import logo from "./logo.svg";
import "./index.scss";
import MainPage from "components/MainPage";


function App() {
	return <div>
    <MainPage/>
  </div>;
}

export default App;
