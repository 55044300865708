import React, { useState, useCallback, useEffect } from "react";
import styles from "./index.module.scss";

const Page1 = ({ scrollToAnchor }) => {

	useEffect(() => {}, []);

	return (
		<div className={styles.page1}>
			<div className={styles.bg}>
				<img src={"./assets/img/首圖.jpg"} alt="bg" />
			</div>
			<div className={styles.text}>學習卡關了 ?</div>
			<div className={styles.bg2}>
				<div className={styles.imgContent}>
					<img src={"./assets/img/痛點/痛點1.jpg"} alt="bg" />
				</div>
				<div className={styles.imgContent}>
					<img src={"./assets/img/痛點/痛點2.jpg"} alt="bg" />
				</div>
				<div className={styles.imgContent}>
					<img src={"./assets/img/痛點/痛點3.jpg"} alt="bg" />
				</div>
			</div>
		</div>
	);
};
export default Page1;
