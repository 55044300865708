import React, { useState, useCallback, useEffect } from "react";
import styles from "./index.module.scss";

const Page4 = ({ scrollToAnchor }) => {
	return (
		<div className={styles.page}>
			<div className={styles.text2}>
				<div className={styles.message}>常見問題</div>
			</div>
			<div className={styles.bg2}>
				<ul>
					<li className={styles.tag}>上課需要什麼設備跟軟體呢？</li>
					<li>
						上課系統是使用網頁開啟，不需要另外下載軟體，只需要準備：
					</li>
					<li>{"✅ 穩定的網路"}</li>
					<li>{"✅ 筆電或平板（PC 需另外準備 Webcam）"}</li>
					<li>{"✅ 有麥克風的耳機"}</li>
					<li className={styles.tag}>
						教材是使用哪一個版本？和孩子學校使用的不一樣怎麼辦？
					</li>
					<li>
						大考沒有版本的分別，比起版本掌握知識點更重要。OneClass
						採用的是跨版本通用的數位教材，會根據每個課程主題要學的知識向度做組合，不用擔心學的跟學校不一樣。
					</li>
					<li className={styles.tag}>是否有紙本教材？</li>
					<li>
						教材使用高科技數位電子書，結合豐富的影音多媒體，不只讓學習不受場域限制，同時增加不同的感官體驗，甚至還有娛樂效果增加孩子的閱讀興趣。
					</li>
					<li className={styles.tag}>你們的師資是怎麼挑選的？</li>
					<li>
						OneClass
						的老師皆經過嚴格的挑選，需有三年以上的實務教學經驗，並經過嚴格的篩選機制:
						海選、初試、試教、培訓、實習、考核，通過後才有授課資格。
					</li>
					<li className={styles.tag}>請問每堂課程上多久呢？</li>
					<li>
						1對1真人教學為國小與國中25分鐘，高中 50
						分鐘，不含課前預習及課後練習時間。
					</li>
				</ul>
			</div>
		</div>
	);
};
export default Page4;
