import React, { useState, useCallback, useEffect } from "react";
import styles from "./index.module.scss";

const Page4 = ({ scrollToAnchor }) => {

	return (
		<div className={styles.page}>
			<div className={styles.text}>專業團隊掌握高分秘訣</div>
			<div className={styles.text2}>
				<div className={styles.message}>一位學生由教學團隊服務</div>
				<div className={styles.message}>授課老師三年以上授課經驗</div>
			</div>
			<div className={styles.bg2}>
				<div className={styles.imgContent}>
					<img src={"./assets/img/特色/特色1.jpg"} alt="bg" />
					<div className={styles.content}>
						<div className={styles.title}>授課老師</div>
						<div className={styles.txt}>
							預習與進度課程重理解，複習與主題課程重應用，有效教學與高互動引導
						</div>
					</div>
				</div>
				<div className={styles.imgContent}>
					<img src={"./assets/img/特色/特色2.jpg"} alt="bg" />
					<div className={styles.content}>
						<div className={styles.title}>輔導老師</div>
						<div className={styles.txt}>
							了解孩子學習背景，媒合合適老師，根據需求推薦專屬學習計畫，追蹤掌握進度同時輔導溝通
						</div>
					</div>
				</div>
				<div className={styles.imgContent}>
					<img src={"./assets/img/特色/特色3.jpg"} alt="bg" />
					<div className={styles.content}>
						<div className={styles.title}>教材設計</div>
						<div className={styles.txt}>
							60年教材設計經驗，豐富題庫和學習資源，依孩子狀況動態調整內容
						</div>
					</div>
				</div>
				<div className={styles.imgContent}>
					<img src={"./assets/img/特色/特色4.jpg"} alt="bg" />
					<div className={styles.content}>
						<div className={styles.title}>教師培訓</div>
						<div className={styles.txt}>
							線上觀課並定期電訪教師給予教學支援，溝通調整教材與教師教法確保教學品質
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default Page4;
