import React, { useState, useCallback, useEffect } from "react";
import styles from "./index.module.scss";

const Page4 = ({ scrollToAnchor }) => {
	return (
		<div className={styles.page}>
			<div className={styles.text}>課前/後測驗數據分析</div>
			<div className={styles.text2}>
				<div className={styles.message}>
					量化學習成效，學習狀況一目瞭然
				</div>
				<div className={styles.message}>並作為調整授課模式的依據</div>
			</div>
			<div className={styles.bg2}>
				<div className={styles.imgContent}>
					<img src={"./assets/img/測驗/測驗1.jpg"} alt="bg" />
				</div>
				<div className={styles.imgContent}>
					<img src={"./assets/img/測驗/測驗2.jpg"} alt="bg" />
				</div>
			</div>
		</div>
	);
};
export default Page4;
