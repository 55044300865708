import React, { useState, useCallback, useEffect } from "react";
import styles from "./index.module.scss";
import YouTube from "react-youtube";

const Page4 = ({ scrollToAnchor }) => {
	const opts = {
		width: "100%",
		height: "270px",
		playerVars: {
			// https://developers.google.com/youtube/player_parameters
			//autoplay: 1,
		},
	};
	return (
		<div className={styles.page}>
			<div className={styles.text}>精彩上課片段</div>
			<div className={styles.text2}>
				<div className={styles.message}>
					使用獨家的上課系統，最符合上課需求
				</div>
				<div className={styles.message}>線上學習沒有阻礙！</div>
			</div>
			<div className={styles.bg2}>
				<div className={styles.imgContent}>
					<YouTube videoId="-HT9NdDkeIE" opts={opts} />
				</div>
				<div className={styles.imgContent}>
					<YouTube videoId="7gdBFULfSlE" opts={opts} />
				</div>
			</div>
		</div>
	);
};
export default Page4;
