import React, { useState, useCallback, useEffect } from "react";
import styles from "./index.module.scss";

const Page3 = ({ scrollToAnchor }) => {

	return (
		<div className={styles.page}>
			<div className={styles.bg}>
				<img src={"./assets/img/Banner/Banner2.jpg"} alt="bg" />
			</div>

			<div className={styles.bg2}>
				<img src={"./assets/img/比較.png"} alt="bg" />
			</div>
		</div>
	);
};
export default Page3;
