import { useEnhanceReducer } from 'customHooks/enhanceReducer';
import * as types from 'constants/actionTypes';

const isMobile = () => {
  try { document.createEvent('TouchEvent'); return true; }
  catch (e) { return false; }
}

const getOneStudyRecordData = () => {
  let data = JSON.parse(localStorage.getItem('onestudy'))
  if (!data) {
    data = []
  }
  return data;
}

export const initState = {
	windowHeight: window.innerHeight,
	windowWidth: window.innerWidth,
	isMobile: isMobile(),
	recordData: getOneStudyRecordData(),
	videoList: [],
	testList: [],
	examRecodeList: [],
	productName: "",
	videoSrc: null,
	educationInfo: null,
	productInfo: null,
	isClassSeletPannel: false,
	playTimeInfo: null,
	nowHeadSelectListName: "",
	isTryOut: false,
	goBackUrl: null,
};

const globalReducer = (state, action) => {
  switch (action.type) {
    case types.SET_VIDEO_LIST:
      return {
			...state,
			videoList: action.videoList,
			isTryOut: action.isTryOut,
		};
    case types.SET_TEST_LIST:
      return {
			...state,
			testList: action.testList,
			productName: action.productName,
		};
    case types.SET_VIDEO_SRC:
      return { ...state, videoSrc: action.videoSrc }
    case types.SET_RECORD_DATA:
      return { ...state, recordData: action.recordData }
    case types.SET_EDUCATION_INFO:
      return { ...state, educationInfo: action.educationInfo }
    case types.SET_PRODUCT_INFO:
      return { ...state, productInfo: action.productInfo }
    case types.SET_CLASS_SELECT_PANNEL:
      return { ...state, isClassSeletPannel: action.isClassSeletPannel }
    case types.SET_PLAY_TIME_INFO:
      return { ...state, playTimeInfo: action.playTimeInfo }
    case types.SET_NOW_HEAD_LIST_SELECT_NAME:
      return { ...state, nowHeadSelectListName: action.nowHeadSelectListName }
    case types.SET_GO_BACK_URL:
      return { ...state, goBackUrl: action.goBackUrl };
    case types.SET_EXAM_LIST:
      return { ...state, examRecodeList: action.examRecodeList };
    default:
      return state;
  }
};

const useGlobalReducer = () => useEnhanceReducer(globalReducer, initState);

export default useGlobalReducer;
