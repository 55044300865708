import React, { useState, useCallback, useEffect } from "react";
import styles from "./index.module.scss";

const Page4 = ({ scrollToAnchor }) => {
	return (
		<div className={styles.page1}>
			<div className={styles.bg}>
				<img src={"./assets/img/Banner/Banner3.jpg"} alt="bg" />
			</div>
			<div className={styles.text}>獲獎紀錄</div>
			<div className={styles.bg2}>
				<div className={styles.imgContent}>
					<img src={"./assets/img/獎狀.jpg"} alt="bg" />
				</div>
				<div className={styles.imgContent}>
					<img src={"./assets/img/參展紀錄.jpg"} alt="bg" />
				</div>
			</div>
		</div>
	);
};
export default Page4;
