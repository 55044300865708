import React, { useState, useCallback, useEffect } from "react";
import styles from "./index.module.scss";

import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const StyledTableCell = withStyles((theme) => ({
	head: {
		backgroundColor: theme.palette.common.black,
		color: theme.palette.common.white,
	},
	body: {
		fontSize: 14,
	},
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
	root: {
		"&:nth-of-type(odd)": {
			backgroundColor: theme.palette.action.hover,
		},
	},
}))(TableRow);

function createData(name, calories, fat, carbs, protein) {
	return { name, calories, fat, carbs, protein };
}

const rows = [
	createData("吳O菖", "勝利國小", "學霸獎", "期中考班排名第一名"),
	createData("許O邦", "再興小學", "學霸獎", ""),
	createData("葛O謙", "木柵國小", "學霸獎", "期中考班排名第一名"),
	createData("何O恩", "正義中學", "學霸獎", "第一次段考班排名第一名"),
	createData("張O萱", "福和國中", "學霸獎", "第一次段考班排名第二名"),
	createData("蘇O耘", "明湖國中", "學霸獎", "第一次段考班排名第一名"),
	createData("吳O祈", "林口國中", "進步獎", "第一次段考數學成績為76分，第二次段考數學成績為93分"),
	createData("何O恩", "正義中學", "進步獎", "上學期期末考數學70分，下學期第一次段考數學82分，進步12分"),
	createData("萬O威", "博愛國小", "進步獎", "期中考數學科獲得滿分"),
];

const useStyles = makeStyles({
	table: {
		minWidth: "100%",
	},
	tableHead: {
		textAlign:"center",
		backgroundColor: "#6db5ee",
	},
});

const Page4 = ({ scrollToAnchor }) => {
	const classes = useStyles();

	return (
		<div className={styles.page}>
			<div className={styles.text}>學員榜單</div>
			<div className={styles.text2}>
				<div className={styles.message}>學習成效有目共睹</div>
				<div className={styles.message}>你也能成為下一個學霸</div>
			</div>
			<div className={styles.bg2}>
				<TableContainer component={Paper}>
					<Table
						className={classes.table}
						aria-label="customized table"
					>
						<TableHead>
							<TableRow>
								<StyledTableCell className={classes.tableHead}>
									學員
								</StyledTableCell>
								<StyledTableCell
									align="center"
									className={classes.tableHead}
								>
									學校
								</StyledTableCell>
								<StyledTableCell
									align="center"
									className={classes.tableHead}
								>
									獲得獎項
								</StyledTableCell>
								<StyledTableCell
									align="center"
									className={classes.tableHead}
								>
									說明
								</StyledTableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{rows.map((row) => (
								<StyledTableRow key={row.name}>
									<StyledTableCell
										component="th"
										scope="row"
										align="center"
									>
										{row.name}
									</StyledTableCell>
									<StyledTableCell align="center">
										{row.calories}
									</StyledTableCell>
									<StyledTableCell align="center">
										{row.fat}
									</StyledTableCell>
									<StyledTableCell align="center">
										{row.carbs}
									</StyledTableCell>
								</StyledTableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</div>
		</div>
	);
};
export default Page4;
