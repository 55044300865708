import React, { useState, useCallback, useEffect, useRef } from "react";
import styles from "./index.module.scss";
import useSetState from "customHooks/setState";
import { useStore, StoreTypes } from "context";
import Header from "components/Header";
import { Beforeunload } from "components/Beforeunload";
import Page1 from "./Page1";
import Page2 from "./Page2";
import Page3 from "./Page3";
import Page4 from "./Page4";
import Page5 from "./Page5";
import Page6 from "./Page6";
import Page7 from "./Page7";
import Page8 from "./Page8";
import Page9 from "./Page9";
import Page10 from "./Page10";
import Page11 from "./Page11";
import Page12 from "./Page12";
import Page13 from "./Page13";
import Page14 from "./Page14";
import Page15 from "./Page15";
import * as actionTypes from "constants/actionTypes";

import classnames from "classnames";


var bodyClass;

const MainPage = () => {

	const [isHeaderBg, setIsHeaderBg] = useState(false);
	const mainRef = useRef()
	
	useEffect(() => {
		if(!mainRef.current)return;
		bodyClass = document.getElementById("root");
		let rootHeight = bodyClass.offsetHeight;
		let mainHeight = mainRef.current.offsetHeight;
		const scrollHandler = (e) => {
			var top = e.target.scrollTop;

			console.log(top, mainHeight - rootHeight);
			if (top < mainHeight - rootHeight) {
				setIsHeaderBg(false);
			} else {
				setIsHeaderBg(true);
			}
		};
		bodyClass.addEventListener("scroll", scrollHandler);
		return () => {
			bodyClass.removeEventListener("scroll", scrollHandler);
		};
	});

	const scrollToAnchor = (anchorName) => {
		if (anchorName) {
			let anchorElement = document.getElementById(anchorName);
			if (anchorElement) {
				anchorElement.scrollIntoView({behavior:'smooth',block:'start'});
			}
		}
	};
	return (
		<div>
			<Header />
			<div ref={mainRef} className={styles.mainPage}>
				<Page1 />
				{/* <Page2 /> */}
				<Page3 />
				<Page4 />
				<Page5 />
				<Page6 />
				<Page7 />
				<Page8 />
				<Page9 />
				<Page10 />
				<Page11 />
				<Page12 />
				<Page13 />
			</div>

			<div
				className={classnames(styles.goForm, {
					[styles.hide]: isHeaderBg,
				})}
			>
				<div
					className={styles.clickBtn}
					onClick={() => scrollToAnchor("formContent")}
				>
					<div>免費課程體驗</div>
				</div>
			</div>
		</div>
	);
};
export default MainPage;
