import React, { useRef, useCallback, useState } from "react";
import styles from "./index.module.scss";
import { API } from "api";
import DialogModule from "components/Dialogs/";

//utm_medium=cpa
// utm_source=affiliates
// utm_term=04373dd80635e9187f2fffbd73306ef9
// vtm_channel=affiliatescomtw
// vtm_stat_id=04373dd80635e9187f2fffbd73306ef9
// vtmz=true
const Page4 = ({ scrollToAnchor }) => {
	const query = new URLSearchParams(window.location.search);
	let utm_medium = query.get("utm_medium");
	let utm_source = query.get("utm_source");
	let utm_term = query.get("utm_term");
	let vtm_channel = query.get("vtm_channel");
	let vtm_stat_id = query.get("vtm_stat_id");
	let vtmz = query.get("vtmz");
	const [open, setOpen] = useState(false);

	const iframeRef = useRef();
	const buy = (url) => {
		window.open(url, "_self");
	};

	const iframOnLoad = useCallback(() => {
		if (!iframeRef.current) return;

		var element = iframeRef.current.contentWindow.document.querySelector(
			"form"
		);
		
		element.addEventListener("submit", async function (event) {
			event.preventDefault();
			let check = true;
			//console.log("---submit----", event);
			var inputEle = iframeRef.current.contentWindow.document.querySelectorAll(
				"input"
			);

			//console.log(inputEle);
			
			// for (let i in inputEle) {
			// 	console.log(inputEle[i]);
			// 	console.log(inputEle[i].value);
			// 	if (
			// 		inputEle[i].autocomplete === "given-name" &&
			// 		inputEle[i].value === ""
			// 	) {
			// 		check = false;
			// 	}
			// 	if (inputEle[i].name === "phone" && inputEle[i].value === "") {
			// 		check = false;
			// 	}
			// 	if (
			// 		inputEle[i].readonly === "readonly" &&
			// 		inputEle[i].value === ""
			// 	) {
			// 		check = false;
			// 	}
			// }

			console.log("check", check);

			window.sendaffiliates();
			//setOpen(true)
		});
		// element.submit(()=>{
		// 	console.log("---submit----");
		// })
		// $("#upload-iframe").load(function () {
		// 	$(this)
		// 		.contents()
		// 		.find("#file_upload_form")
		// 		.submit(function () {
		// 			$("input[name='fileuploaded']").val("some value"); // updated
		// 			return true; //return false prevents submit
		// 		});
		// });
	}, [iframeRef]);

	return (
		<div id="formContent" className={styles.page1}>
			<div className={styles.bg}>
				<img src={"./assets/img/Banner/Banner5-正方.jpg"} alt="bg" />
			</div>
			<div
				className={styles.btn}
				onClick={() => buy("https://p.ecpay.com.tw/397DFB7")}
			>
				<div className={styles.formContent}>
					<iframe
						ref={iframeRef}
						src="./b24form.html"
						title="b24form"
						onLoad={() => iframOnLoad()}
					/>
				</div>
			</div>
			<DialogModule
				open={open}
				content={<img className={styles.endImg} src={"./assets/img/填寫完成.png"} alt="bg" />}
			/>
		</div>
	);
};
export default Page4;
