import React, { useState, useCallback, useEffect } from "react";
import styles from "./index.module.scss";

const Header = () => {

	const buy = (url) => {
		window.open(url, "_self");
	};

	return (
		<div className={styles.header}>
			<div
				className={styles.logo}
				onClick={() => buy("https://tutor.oneclass.com.tw/")}
			>
				<img
					src="./assets/img/Oneclass真人Live家教_LOGO-011.png"
					alt=""
				/>
			</div>
		</div>
	);
};
export default Header;
