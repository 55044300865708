import React, { useState, useCallback, useEffect } from "react";
import styles from "./index.module.scss";

const Page14 = ({ scrollToAnchor }) => {
	return (
		<div className={styles.page}>
			<div className={styles.bg}>
				<img src={"./assets/img/Banner/Banner4.jpg"} alt="bg" />
			</div>
			<div className={styles.bg2}>
				<div className={styles.imgList}>
					<div className={styles.imgContent}>
						<img
							src={"./assets/img/購課流程/購課流程1.jpg"}
							alt="bg"
						/>
					</div>
					<div className={styles.imgContent}>
						<img
							src={"./assets/img/購課流程/購課流程2.jpg"}
							alt="bg"
						/>
					</div>
					<div className={styles.imgContent}>
						<img
							src={"./assets/img/購課流程/購課流程3.jpg"}
							alt="bg"
						/>
					</div>
					<div className={styles.imgContent}>
						<img
							src={"./assets/img/購課流程/購課流程4.jpg"}
							alt="bg"
						/>
					</div>
				</div>
			</div>
		</div>
	);
};
export default Page14;
